html {
    font-size: 20px;
    padding: 0 1rem 1rem;
    min-height: 100%;
    box-sizing: border-box;
    display: flex;
    font-family: "Palatino";
}

body {
    color: #333;
    background: white;
    max-width: 640px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

h1, h2 {
    font-family: Courier;
    font-weight: 700;
}

a {
    color: black;
    opacity: .25;
}

a[href] {
    opacity: 1;
}

p {
    b {
        color: black;
    }
}

header {
    display: flex;
    flex-direction: column;

    h1 {
        width: 100%;
        text-align: center;
        font-size: 2.5rem;
    }

    p {
        flex: 1 1 100%;
        max-width: 20rem;
        text-align: justify;
        margin: auto;
        margin-top: 2rem;

        b {
            font-family: Courier;
        }
    }
}

.avatar {
    height: 8rem;
    width: 8rem;
    border-radius: 9%;
    margin: auto;
    border: 0.1rem solid black;
}

section {
    margin-top: 3rem;
}

article {
    margin: 1rem;
    display: block;
    white-space: normal;

    p b {
        color: black;
    }
    
    * {
        font-size: 1rem;
        margin: 0;
    }

    > * {
        margin-right: .5rem;
    }

    @media (min-width: 640px) {
        display: flex;
        white-space: nowrap;

        > p:before {
            content: "- ";
        }
    }
}

footer {
    text-align: center;
    margin-top: auto;
    opacity: .9;
    padding-top: 3rem;

    a {
        color: transparent;
        margin: 0 2rem;
    }

    a > img {
        height: 3rem;
        width: 3rem;
        filter: invert(1)
    }
}

.donate {
    margin-top: 3rem;

    p {
        margin: .5rem;
        font-size: .7rem;
    }
}
